.comments-panel-container {
  border-left: 1px dotted;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  right: 0;
  top: 0;
  transition: width 0.2s ease-in-out;
  z-index: 1;
}

.comments-panel-header {
  display: flex;
  padding: 12px;
}

.comments-panel-header-text {
  display: flex;
  flex-grow: 1;
  font-size: 12pt;
  font-weight: bold;
  justify-content: center;
  padding-left: 40px;
}

.comments-panel-header-close {
  cursor: pointer;
  display: flex;
  width: auto;
}

.comments-panel-wrapper {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 50px);
  width: 100%;
}