.photo-thumb-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  position: relative;
  width: 60px;
}

.photo-thumb-item-image-wrapper {
  align-items: center;
  border-radius: 5pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60px;
  overflow: hidden;
  width: 60px;
}

.photo-thumb-item-image {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}
