.pins-search-container {
    align-items: center;
    border-radius: 16px;
    display: flex;
    height: 34px;
    justify-content: left;
    padding-left: 10px;
    width: 210px;
}

.pins-search-input {
    background-color: red;
    border: 0px;
    outline: none;
    flex-shrink: 1;
    margin-left: 4px;
    margin-right: 10px;
    width: 100%;
}