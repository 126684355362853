.comment-top-toggle-container {
    margin-bottom: 4px;
}

.comment-top-toggle-button {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: bold;
    padding-right: 12px;
    cursor: pointer;
  }

  .comment-top-toggle-bar {
    height: 1px;
    margin-right: 14px;
    width: 32px;
  }