.note-thumb-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  position: relative;
  width: 60px;
}

.note-thumb-item-icon-container {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 60px;
  justify-content: center;
  opacity: 0.6;
  width: 60px;
}