.modal-checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: left;
  width: auto;
}

.modal-checkbox-input {
  align-items: center;
  display: flex;
  height: 100%;
}

.modal-checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 10pt;
}