.modal-buttons-container {
  align-items: center;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 70px;
  justify-content: center;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 9;
}

.modal-buttons-mask {
  bottom: 0px;
  left: 0px;
  height: 70px;
  opacity: 0.7;
  position: absolute;
  right: 0px;
  z-index: 0;
}