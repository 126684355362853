.event-time-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  padding-left: 4px;
}

.event-time-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 10pt;
  font-weight: bold;
  height: 36px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 115px;
}