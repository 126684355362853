.contributor-badge-container {
  align-items: center;
  border-radius: 20px;
  display: flex;
  gap: 8px;
  height: 40px;
  left: 10px;
  padding: 3px;
  position: absolute;
  top: 50px;
  width: auto;
  z-index: 8;
}

.contributor-badge-left {
  display: flex;
}

.contributor-badge-center {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.contributor-badge-center-top {
  display: flex;
  font-size: 8pt;
}

.contributor-badge-center-bottom {
  display: flex;
  font-size: 9pt;
  font-weight: bold;
}

.contributor-badge-right {
  align-items: center;
  display: flex;
}

.contributor-badge-gift-button {
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  height: 34px;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  width: auto;
}

.contributor-badge-gift-button-label {
  font-size: 9pt;
  font-weight: bold;
}