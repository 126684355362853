.calendar-header-button {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    height: 36px;
    justify-content: center;
    padding: 12px;
}