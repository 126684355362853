.friend-selector-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.friend-selector-list-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 11pt;
  font-weight: bold;
  gap: 8px;
  margin-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.friend-selector-list {
  flex-grow: 1;
  padding: 12px;
  padding-left: 0px;
  padding-right: 4px;
}

.friend-selector-item {
  align-items: center;
  display: flex;
  padding-right: 14px;
  width: 100%;
}

.friend-selector-item-name {
  flex-grow: 1;
}

.friend-selector-item-checkbox {
  display: flex;
  justify-content: center;
  width: 30px;
}