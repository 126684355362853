.song-edit-list {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 10px;
  padding-top: 20px;
}

.song-edit-item-container {
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  padding: 10px;
}

.song-edit-item-cover {
  border-radius: 5px;
  height: 56px;
  object-fit: cover;
  width: 56px;
}

.song-edit-item-details {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
}

.song-edit-item-title {
  font-size: 10pt;
  font-weight: bold;
}

.song-edit-item-artist {
  font-size: 9pt;
  font-weight: normal;
}

.song-edit-item-duration {
  font-size: 8pt;
  font-weight: normal;
}

.song-edit-play-control {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}