.friends-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  
  .friends-list {
    flex-grow: 1;
    padding: 12px;
    padding-left: 0px;
    padding-right: 4px;
  }
  
  .friends-item {
    align-items: center;
    display: flex;
    padding-right: 14px;
    width: 100%;
  }
  
  .friends-item-name {
    flex-grow: 1;
  }
  
  .friends-item-checkbox {
    display: flex;
    justify-content: center;
    width: 30px;
  }