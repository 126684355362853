.pins-toolbar-slider-wrapper {
    width: 100px;
}

.pins-toolbar-slider-radius {
    font-size: 9pt;
    margin-left: 20px;
    text-wrap: nowrap;
}

.pins-toolbar-slider-miles {
    font-size: 9pt;
    margin-right: 10px;
    text-wrap: nowrap;
    width: 80px;
}

.pins-toolbar-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
}

.pins-toolbar-button {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    font-size: 9pt;
    font-weight: bold;
    height: 34px;
    justify-content: center;
    padding: 12px;
    text-wrap: nowrap;
}