.slider-container {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
}

.slider-thumb {
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
}

.slider-track {
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    width: 100%;
}

.opacity-value-display {
    margin-left: 20px;
    min-width: 40px;
    text-align: center;
}
