.post-options-more-button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 3px;
  padding-top: 3px;
  width: 100%;
}

.post-options-more-button-top {
  align-items: center;
  display: flex;
  width: 100%;
}

.post-options-more-button-bottom {
  align-items: center;
  display: flex;
  font-size: 10pt;
  font-weight: normal;
  padding: 10px;
  padding-bottom: 2px;
  width: 100%;
}

.post-options-more-button-label {
  flex-grow: 1;
  font-size: 13pt;
  font-weight: bold;
  padding-left: 4px;
}