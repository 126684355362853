.post-date-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  padding-left: 4px;
}

.post-date-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10pt;
  font-weight: bold;
  height: 36px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 155px;
}

/* POPUP */
.post-date-popup {
  border-radius: 11px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1000;
}