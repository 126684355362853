.event-wizard-add-media-button-container {
    border-radius: 11px;
    cursor: pointer;
    padding: 8px;
    text-align: center;
    width: 100%;
}

.event-wizard-add-media-button-label {
    font-size: 8pt;
    font-weight: bold;
    text-align: center;
}