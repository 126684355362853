.event-wizard-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden; /* Hide sliding content */
  }
  
  .event-wizard-step-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    height: 100%;
    position: absolute; /* Stack steps for animation */
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .event-wizard-step-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 00px;
    padding-top: 20px;
    width: 100%;
  }
  
  .event-wizard-step-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 50px;
    justify-content: center;
    width: 100%;
  }
  
  .event-wizard-step-button {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    height: 36px;
    justify-content: center;
    padding: 12px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100px;
  }