.comments-add-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.comments-add-input-container {
  align-items: center;
  display: flex;
  box-sizing: border-box;
  font-size: 10pt;
  font-weight: normal;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  flex-grow: 1;
  height: 40px;
  line-height: 1.5em;
  outline: none;
  padding: 10px;
}