.home-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.home-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
}

.home-scroll-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll; /* Allows vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  position: relative;
  scroll-snap-type: y mandatory; /* Ensures snapping is enforced */
  scroll-behavior: smooth; /* Smooth scrolling */
  width: 100%;
  -webkit-overflow-scrolling: touch; /* Improves scrolling on iOS */
  overscroll-behavior-y: contain; /* Prevents overscroll bounce */
  z-index: 1;

  /* Hide scrollbars on mobile */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera on mobile */
.home-scroll-container::-webkit-scrollbar {
  display: none;
}

/* Media query for desktop (show scrollbars) */
@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .home-scroll-container {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
  }

  .home-scroll-container::-webkit-scrollbar {
    display: block; /* Show scrollbar on desktop */
    width: 8px; /* Optional: customize scrollbar width */
  }

  .home-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Optional: customize scrollbar color */
    border-radius: 4px;
  }

  .home-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Optional: customize track color */
  }
}

.home-item {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always; /* Forces stop at each snap point */
  width: 520px;
  z-index: 0;
}

.home-item-wrapper {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
  scroll-snap-align: start;
}

.home-item-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  padding-bottom: 50px;
  width: 60px;
  z-index: 2;
}