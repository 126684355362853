.thumb-delete-container {
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 20px;
  z-index: 2;
}