.note-container {
    align-items: center;
    border: 0px solid;
    border-radius: 17px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 50px;
    position: relative;
    width: 100%;
}



.note-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.note-header-left {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}   

.note-header-right {
    align-items: center;
    display: flex;
    flex-direction: row;
}   

.note-title {
    font-size: 20pt;
    font-weight: bold;
    padding-top: 6px;
    padding-bottom: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.note-author {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 100%;
}

.note-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.note-header {
    display: flex;
    flex-direction: row;
}

.note-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.note-gallery-wrapper {
    border-radius: 17px;
    height: auto;
    margin-top: 18px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
}

.note-object-wrapper {
    border-radius: 13px;
    padding: 8px;
    width: 440px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}

.note-event-wrapper {
    border-radius: 13px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}

.note-link-wrapper {
    border-radius: 13px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}

.note-dot {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.note-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.note-posted {
    font-weight: normal;
}

.note-timestamp {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.note-content {
    font-size: 12pt;
    line-height: 1.5em;
    padding-bottom: 0px;
    padding-top: 8px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.note-comment-section {
    margin-left: 42px;
}

.note-documents-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-left: 0px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.note-object {
    border-radius: 13px;
    margin: 20px;
    margin-left: 45px;
    padding: 8px;
    width: 440px;
}

@media (max-width: 600px) {

    .note-gallery-wrapper {
        width: 100%;
    }

    .note-object-wrapper {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

}