.comment-bottom-toggle-container {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

.comment-bottom-toggle-bar {
    height: 1px;
    margin-right: 14px;
    width: 32px;
  }

.comment-bottom-toggle-replies {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: bold;
    padding-right: 12px;
    cursor: pointer;
  }