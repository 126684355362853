.menu-bar-container {
    border-right: 0px solid;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    z-index: 1000;
}

.menu-bar-top {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
    padding-top: 6px;
}
.menu-bar-pin-wrapper {
    align-items: center;
    border-left: 3px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    opacity: 0.8;
    position: relative;
    width: 60px;
}

.menu-bar-dot-wrapper {
    align-items: center;
    border-left: 3px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    opacity: 0.8;
    position: relative;
    width: 60px;
}

.menu-bar-bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: auto;
    padding-bottom: 16px;
    padding-top: 20px;
}

.menu-bar-add-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    position: relative;
    width: 60px;
}