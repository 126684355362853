.contribute-menu-container {
    border-radius: 17px;
    height: auto;
    margin: auto;
    padding: 14px;
    padding-bottom: 18px;
    padding-top: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 250px;
    z-index: 10;
  }
  
  .contribute-menu-mask {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 9;
  }