.pins-map-container {
  height: calc(100vh - 56px);
  width: 100%;
}

.pins-map-zoom-button {
    background-color: #333;
    border: 1px solid;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 5px;
    font-size: 24px;
    user-select: none;
  }