.public-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  
  .public-items {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding-top: 10px;
  }
  
  .public-scroll-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    scroll-snap-type: y mandatory; /* Ensures snapping is enforced */
    scroll-behavior: smooth; /* Smooth scrolling */
    width: 100%;
    -webkit-overflow-scrolling: touch; /* Improves scrolling on iOS */
    overscroll-behavior-y: contain; /* Prevents overscroll bounce */
  }
  
  .public-item {
    align-items: flex-end;
    border-radius: 17px;
    display: flex;
    flex: 0 0 auto;
    height: 90vh;
    justify-content: center;
    scroll-snap-align: start;
    scroll-snap-stop: always; /* Forces stop at each snap point */
    width: 520px;
    z-index: 0;
  }
  
  .public-item-wrapper {
    align-items: flex-end;
    border-radius: 17px;
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    justify-content: center;
    scroll-snap-align: start;
  }
  
  .public-item-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 10px;
    width: 60px;
  }