.video-thumb-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  position: relative;
  width: 60px;
}

.video-thumb-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 60px;
  justify-content: center;
  padding-bottom: 100px;
  opacity: 0.6;
  width: 60px;
}

.video-thumb-item-video-container {
  align-items: center;
  border-radius: 5pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60px;
  overflow: hidden;
  width: 60px;
}

.video-thumb-item-video {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}