.note-form-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.note-form-title-wrapper {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.note-form-title-left {
    flex-grow: 1;
    flex-shrink: 1;
}

.note-form-title-right {
    align-items: center;
    display: flex;
    width: auto;
}

.note-form-title-textarea {
    font-size: 20px;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 4px;
    margin-top: 9px;
    outline: none;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    resize: none;
    overflow: hidden;
}

.note-form-save-button-wrapper {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    height: 36px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    width: auto;
}

.note-form-content-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.note-form-content {
    background-color: transparent;
    border-color: transparent;
    font-size: 14pt;
    font-weight: bold;
    border-width: 1px;
    border-style: solid;
    line-height: 1.5;
    outline: none;
    overflow-y: auto;
    width: 100%;
    resize: none;
}