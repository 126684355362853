.emoji-selector-container {
    display: flex;
    overflow-x: auto;
    padding: 8px;
    padding-left: 0px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 0px;
    white-space: nowrap;
    scrollbar-width: thin; /* For Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.emoji-selector-container::-webkit-scrollbar {
    display: none;
}

.emoji-button {
    background: none;
    border: none;
    font-size: 20px;
    padding: 0 8px;
    cursor: pointer;
    outline: none;
}

.emoji-button:hover {
    opacity: 0.8;
}