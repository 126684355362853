.post-wizard-preview-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
}

.post-wizard-preview-top {
    display: column;
    flex-grow: 1;
    padding-top: 8px;
    width: 100%;
}

.post-wizard-preview-buttons {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    height: auto;
    overflow-y: auto;
    padding: 8px;
    padding-top: 4px;
    width: 100%;
}

.post-wizard-preview-item {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    padding-top: 4px;
    width: 100%;
}

.post-wizard-preview-media {
    display: flex;
    flex-direction: row;
    gap: 14px;
    height: 80px;
    justify-content: center;
    margin-bottom: 16px;
    overflow-x: auto;
    padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    width: 100%;
}

.post-wizard-preview-no-slides {
    align-items: center;
    display: flex;
    font-size: 12pt;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    opacity: 0.2;
    width: 100%;
  }

.post-wizard-preview-media-item {
    height: auto;
    width: auto;
}

.post-wizard-preview-overflow {
    display: flex;
    height: 295px;
    overflow: hidden;
    width: 340px;
}

.post-wizard-preview {
    border-radius: 17px;
    height: 492px;
    overflow: hidden;
    transform: scale(0.6);
    transform-origin: top;
    width: 287%;
}