.music-manager-container {
  padding: 20px;
}

.song-list-header {
  margin-bottom: 15px;
}

.add-song-button {
  padding: 8px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-song-button:hover {
  background-color: #218838;
}

.song-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.song-form input,
.song-form button {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.song-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.song-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.song-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.song-item-container {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.song-item-cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.song-item-details {
  flex: 1;
}

.song-item-details h3 {
  margin: 0 0 5px 0;
}

.song-item-details p {
  margin: 0;
  color: #666;
}

.edit-button {
  padding: 5px 10px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}