.item-button-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

.item-button-circle {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.item-button-count {
  font-size: 10pt;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.item-button-label {
  font-size: 10pt;
  font-weight: normal;
}