.home-menu-button-container {
    align-items: center;
    border-left: 3px solid transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    position: relative;
    width: 60px;
}

.home-menu-button-circle {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 30px;
    overflow: hidden;
    transition: background-color 0.2s;
    user-select: none;
    width: 30px;
}

.home-menu-button-count {
    align-items: center;
    border: 3px solid;
    border-radius: 11px;
    top: -6px;
    display: flex;
    flex-direction: row;
    font-family: Arial;
    font-size: 9px;
    height: 22px;
    justify-content: center;
    right: 4px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    width: auto;
    z-index: 1;
}