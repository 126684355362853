.comments-add-container {
  padding-top: 10px;
} 

.comments-add-buttons-wrapper {
  height: 34px;
  display: flex;
  justify-content: right;
  margin-top: 6px;
  width: 100%;
}