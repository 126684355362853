.grid-item-container {
    align-items: center;
    border: 0px solid;
    border-radius: 11px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
}