.post-options-panel-container {
    height: auto;
    width: 100%;
}

.post-options-panel-title {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    gap: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.post-options-panel-sections {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.post-options-panel-section-title {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    gap: 8px;
    margin-bottom: 8px;
    padding-left: 25px;
    padding-right: 20px;
}

.post-options-panel-row {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    padding-left: 40px;
    padding-right: 14px;
    width: 100%;
}

.post-options-panel-option-label {
  cursor: pointer;
  font-size: 10pt;
  font-weight: normal;
}

.post-options-panel-option-summary-row {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: normal;
    gap: 8px;
    margin-bottom: 8px;
    padding-left: 50px;
    padding-right: 14px;
  }