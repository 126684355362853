.event-display-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.event-display-left {
  align-items: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;
  justify-content: center;
}

.event-display-right {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.event-display-title {
  font-size: 11pt;
  font-weight: bold;
}

.event-display-details {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.event-display-dates {
  font-size:9pt;
  font-weight: bold;
  width: 100%;
  line-height: 1.6; /* Ensures readability with line breaks */
}