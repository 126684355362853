.comment-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 1;
    gap: 6px;
    padding-left: 12px;
    padding-right: 12px;
}

.comment-profile-dot-wrapper {
    width: 30px;
}

.comment-name {
    font-size: 9pt;
    font-weight: bold;
}

.comment-text {
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 10pt;
    line-height: 1.3em;
    padding-right: 12px;
}

.comment-show-reply-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: bold;
    width: auto;
}

.comment-footer {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.comment-container {
    display: flex;
    flex-direction: row;
    margin-left: 18pt;
    margin-top: 6px;
    padding-bottom: 12pt;
}

.comment-replies {
    margin-left: 0px;
    margin-top: 8px;
}