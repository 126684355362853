.pins-map-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.pins-map-bottom {
    display: flex;
    flex-grow: 1;
}

.pins-map-left {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    padding: 10px;
    padding-top: 0px;
}

.pins-map-right {
    flex-grow: 1;
    height: 100%;
}

.pins-map-following {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 10px;
    margin-top: 6px;
}

.pins-map-pins {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}