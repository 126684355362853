.privacy-pins-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.privacy-pins-list-title {
  align-items: center;
  display: flex;
  font-size: 11pt;
  font-weight: bold;
  height: 40px;
  justify-content: left;
  padding-left: 20px;
}

.privacy-pins-list {
  flex-grow: 1;
  padding: 12px;
  padding-left: 0px;
  padding-right: 4px;
}

.privacy-pins-item {
  align-items: top;
  display: flex;
  padding-right: 14px;
  width: 100%;
}

.privacy-pins-item-name {
  flex-grow: 1;
}

.privacy-pins-item-checkbox {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  width: 30px;
}