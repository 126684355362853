.comments-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.comments-list {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

.comments-add-wrapper {
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-top: 14px;
  width: 100%;
}