.post-options-container {
  padding: 14px;
  padding-top: 8px;
  padding-bottom: 0px;
}

.post-options-hint {
  font-size: 10pt;
  line-height: 1.5em;
  padding: 14px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.post-options-title-wrapper {
  height: auto;
  width: 100%;
}

.post-options-title-label {
  font-size: 10pt;
  padding-left: 2px;
}

.post-options-title-textarea {
  background-color: transparent;
  border: 0px;
  font-size: 10pt;
  font-weight: bold;
  height: auto;
  resize: none;
  outline: none;
  padding: 14px;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
}