.home-menu-add-button-container {
    align-items: center;
    border-bottom: 0px solid;
    border-top: 0px solid;
    border-left: 5px solid;
    border-right: 5px solid;
    border-radius: 9px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
}