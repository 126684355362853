.note-grid-item-container {
  align-items: center;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  position: relative;
  width: 100%;
}

.note-grid-item-image-wrapper {
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  display: flex; 
  flex-direction: row;
  max-height: 200px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.note-grid-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  width: 100%;
}

.note-grid-item-first-block-text {
  font-size: 9pt;
  line-height: 1.5em;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  width: 100%;
}

.note-grid-item-more {
  font-size: 9pt;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  width: 100%;
}