.modal-switch-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-switch-label {
    align-items: center;
    display: flex;
    font-size: 12pt;
    font-weight: bold;
    padding-left: 5px;
}