.item-menu-button-container {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    width: 100%;
}

.item-menu-button-icon {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    margin-top: 1px;
    width: 20px;
}

.item-menu-button-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    flex-grow: 1;
    padding-left: 12px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}