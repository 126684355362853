.home-toolbar-button-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
}

.home-toolbar-button-title {
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    justify-content: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.home-toolbar-button-bar-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 14px;
    width: 100%;
}

.home-toolbar-button-bar {
    border-radius: 2px;
    height: 4px;
    width: 40px;
}