.song-grid-item-container {
  align-items: center;
  border: 1px solid;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.song-grid-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 30px;
  opacity: 0.6;
  width: 100%;
}

.song-grid-item-about {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  bottom: 0px;
  color: white;
  display: flex;
  font-size: 7pt;
  font-weight: normal;
  justify-content: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
