.comments-popup-container {
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: height 0.2s ease-in-out;
  z-index: 10;
}

.comments-popup-header {
  display: flex;
  height: 43px;
  padding: 12px;
}

.comments-popup-header-text {
  display: flex;
  flex-grow: 1;
  font-size: 12pt;
  font-weight: bold;
  justify-content: center;
  padding-left: 40px;
}

.comments-popup-header-close {
  cursor: pointer;
  display: flex;
  width: auto;
}

.comments-popup-wrapper {
  display: flex;
  height: calc(100% - 43px);
  width: 100%;
}

.comments-popup-mask {
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 9;
}