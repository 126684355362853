.video-grid-item-container {
  align-items: center;
  border: 0px solid;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-grid-item-icon-container {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 270px;
  justify-content: center;
  padding-bottom: 100px;
  opacity: 0.6;
  width: 100%;
}

.video-grid-item-footer {
  align-items: center;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: auto;
  padding: 14px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.video-grid-item-title {
  font-size: 9pt;
  font-weight: bold;
}

.video-grid-item-image-container {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  min-height: 250px;
  overflow: hidden;
  position: relative;
  width: 100%;
}