.comment-scroller-container {
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.comment-scroller {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  bottom: 0;
  padding-left: 8px;
  align-items: flex-start; /* Align items to the start */
  animation: scrollUp 20s linear forwards;
}

.comment-item {
  align-items: center;
  display: flex;
  font-size: 10pt;
  font-weight: bold;
  gap: 8px;
  line-height: 1.5em;
  padding: 5px;
  padding-right: 12px;
  border-radius: 17px;
  width: fit-content; /* Make width fit the content */
  animation: fadeInOut 20s linear forwards;
}

.comment-profile-dot {
  width: 24px;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}