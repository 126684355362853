

.pin-container {
    align-items: top;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    height: auto;
    padding: 12px;
    padding-bottom: 14px;
    user-select: none;
}

.pin-left {
    align-items: flex-start;
    display: flex;
    height: 100%;
    padding-top: 3px;
    width: 33px;
}

.pin-right {
    align-items: left;
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-grow: 1;
}

.pin-title {
    align-items: center;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    width: 100%;
}

.pin-description {
    align-items: center;
    display: flex;
    font-size: 9pt;
    font-weight: normal;
}

.pin-home {
    align-items: center;
    border-radius: 13px;
    display: flex;
    font-size: 8pt;
    font-weight: bold;
    height: 26px;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
    width: 70px;
}

.pin-home-description {
    align-items: center;
    display: flex;
    font-size: 9pt;
    font-style: italic;
    font-weight: normal;
}