.notification {
  align-items: center;
  display: flex;
  gap: 12px;
  padding: 12px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.notification-text {
  flex-shrink: 1;
  font-size: 14px;
  width: 100%;
}

.notification-profile-dot-wrapper {
  width: 40px;
}