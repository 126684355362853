.comment-reply-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
}

.comment-reply-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;
    padding-left: 12px;
}

.comment-reply-header {
    align-items: center;
    display: flex;
    font-size: 9pt;
    font-weight: bold;
    gap: 8px;
  }