.post-feed-item-container {
  align-items: center;
  border: 0px solid;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.post-feed-item-no-content-added-wrapper {
  align-items: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 50px;
  opacity: 0.6;
  width: 100%;
}

.post-feed-item-no-content-added-text {
  font-size: 20pt;
  font-weight: bold;
  opacity: 0.2;
}

/* Container for the slideshow */
.post-feed-item-slideshow-container {
  height: 100%;
  /* Inherits from parent, e.g., post-feed-item-container */
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* Swiper base styles */
.swiper {
  height: 100%;
  width: 100%;
}

.swiper-slide {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Wrapper for slide content */
.post-feed-item-slide-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  /* Contain overflow within slide */
}

/* Base image styles */
.post-feed-item-image {
  display: block;
  max-width: 100%;
  width: 100%;
  /* Allow overflow */
  max-height: none;

  /* Allow overflow */
}

/* Portrait images: full height, overflow sides */
.post-feed-item-image.portrait {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* Crop sides */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  /* Center horizontally */
}

/* Landscape images: full width, centered vertically */
.post-feed-item-image.landscape {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* Center vertically */
}

/* Customize pagination dots */
.swiper-pagination {
  bottom: unset !important;
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
  position: absolute !important;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.4 !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
  /* Default dot color */
  opacity: 1 !important;
  /* Active dot fully opaque */
}

.post-feed-item-custom-arrow {
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 20px);
  transform: translateY(-50%);
  width: 40px;
  z-index: 10;
}

.post-feed-item-custom-arrow-prev {
  left: 10px;
}

.post-feed-item-custom-arrow-next {
  right: 10px;
}