.post-feed-item-footer-container {
  align-items: left;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  height: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.post-feed-item-footer-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  padding: 16px;
  user-select: none;
  width: 100%;
}

.post-feed-item-footer-top-row {
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
}

.post-feed-item-footer-top-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.post-feed-item-footer-top-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: right;
}

.post-feed-item-footer-username {
  font-size: 11pt;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.post-feed-item-footer-contributors {
  font-size: 8pt;
  font-weight: normal;
  padding-left: 24px;
  margin-bottom: 4px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.post-feed-item-footer-title {
  font-size: 11pt;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.post-feed-item-footer-location {
  align-items: center;
  display: flex;
  font-size: 8pt;
  font-weight: bold;
  gap: 4px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.post-feed-item-footer-button {
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  height: 34px;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 150px;
}

.post-feed-item-footer-button-label {
  font-size: 9pt;
  font-weight: bold;
}

.post-feed-item-song-marquee {
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-right: 12px;
  overflow: hidden;
  /* Hide overflowing text */
  white-space: nowrap;
  /* Keep text on one line */
  z-index: 2;
  /* Ensure it’s above the slideshow */
  width: 150px;
}

.post-feed-item-marquee-content {
  animation: post-feed-item-marquee-scroll 30s linear infinite;
  /* Adjust duration (10s) for speed */
  display: flex;
  align-items: center;
  font-size: 9pt;
  width: 100%;
}

/* Animation keyframes */
@keyframes post-feed-item-marquee-scroll {
  0% {
    transform: translateX(100%);
    /* Start off-screen to the right */
  }

  100% {
    transform: translateX(-100%);
    /* End off-screen to the left */
  }
}

.post-feed-item-footer-floating-comments {
  height: 150px;
  left: 0px;
  width: 350px;
}