.event-dates-container {
    display: flex;
}

.event-dates-span {
    border-left: 5px solid;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
    margin-top: 17px;
    margin-bottom: 17px;
    width: 16px;
}

.event-dates-rows {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.event-dates-row {
    display: flex;
    flex-direction: row;
}