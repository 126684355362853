.contribute-button-container {
  align-items: left;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 50px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  user-select: none;
  width: 150px;
}

.contribute-button-top {
  align-items: center;
  display: flex;
  font-size: 8pt;
  font-weight: normal;
  gap: 4px;
  justify-content: center;
}

.contribute-button-bottom {
  align-items: center;
  display: flex;
  font-size: 9pt;
  font-weight: bold;
  gap: 7px;
  justify-content: center;
  padding-left: 2px;
}

.sliding-word {
  animation: slideIn 0.3s ease-in-out;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}