.comment-footer-left {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 20px;
}

.comment-footer-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 20px;
  justify-content: right;
  margin-right: 20px;
}

.comment-footer-elapsed {
  align-items: center;
  display: flex;
  font-size: 8pt;
  font-weight: normal;
}

.comment-footer-dislike-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.comment-footer-likes-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.comment-footer-likes-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.comment-footer-likes-count {
  align-items: center;
  font-size: 9pt;
  display: flex;
  flex-direction: row;
}