.stream-grid-item-container {
  align-items: center;
  border: 0px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.stream-grid-item-image-wrapper {
  align-items: center;
  border-radius: 11px;
  display: flex; 
  flex-direction: row;
  max-height: 200px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}

.stream-grid-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  width: 100%;
  z-index: 1;
}

.stream-grid-item-footer {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.stream-grid-item-title {
  color: white;
  display: flex;
  font-size: 7pt;
  font-weight: normal;
  justify-content: center;
  width: 100%;
}

.stream-grid-item-timer {
  color: #dddddd;
  display: flex;
  font-size: 7pt;
  font-weight: normal;
  justify-content: center;
  width: 100%;
}

.stream-grid-item-status-dot {
  position: absolute;
  top: 10px;    
  right: 10px;  
  width: 12px;
  height: 12px;
  background-color: #009900;
  border: 1px solid white;
  border-radius: 50%; 
  z-index: 3; 
}