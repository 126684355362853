

.item-viewer-container {
    align-items: flex-end;
    border-radius: 17px;
    display: flex;
    flex: 0 0 auto;
    height: 90vh;
    justify-content: left;
    position: relative;
    width: 520px;
    z-index: 0;
  }
  
  .item-viewer-item {
    align-items: flex-end;
    border-radius: 17px;
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    justify-content: center;
    position: absolute;
    scroll-snap-align: start;
    z-index: 0;
  }
  
  .item-viewer-buttons {
    align-items: center;
    top: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 450px;
    padding-top: 10px;
    position: absolute;
    right: 0px;
    width: 60px;
    z-index: 1;
  }