.post-stream-container {
    background-color: red;
    border-radius: 15px;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 14px;
    overflow: hidden;
    width: 100%;
}

.post-stream {
    height: 100%;
    width: auto;
}