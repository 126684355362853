.wizard-step-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 546px;
    height: 100%;
    position: absolute;
    /* Stack steps for animation */
    left: 0;
    top: 0;
    width: 100%;
}